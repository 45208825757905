<template>
  <b-container :show="$apollo.loading">
    <modal-incompatible-item
      v-if="currentItemObject && currentItemObject.incompatibleItem
        && userObject && !userObject.isSysAdmin"
      :item-name="currentItemObject.name"
      :modal-for-detail-page="true"
    ></modal-incompatible-item>
    <b-tabs
      v-else-if="!$apollo.loading && currentItemObject && $hasPrivileges(
      $privileges('CAN_EDIT_PROJECT_SETTINGS'),
      userObject.isSysAdmin,
      currentItemObject.privilegesForItem,
    )"
      vertical
      nav-wrapper-class="col-3"
      nav-class="mt-5"
      v-model="tabIndex"
    >
      <template #tabs-start>
        <h3>{{ $t('settings.settings') }}</h3>
      </template>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_PUBLICATION'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        @click="setUrl('')"
      >
        <template #title>{{ $t('settings.project.publications.tabName') }}</template>
        <project-settings-publications
          v-if="userObject"
          class="mt-5"
          :is-sys-admin="userObject.isSysAdmin"
          :publication-data-prop="publicationData"
          :sibling-slugs="allChildSlugsOfParentFolder"
          :project-tags="projectTags"
          :all-tags="allTagsOfGroup"
          @updatePublicationSettings="updatePublicationSettings"
        ></project-settings-publications>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_CI'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.project.corporateIdentity.hash')"
        @click="setUrl($t('settings.project.corporateIdentity.hash'))"
      >
        <template #title>{{ $t('settings.project.corporateIdentity.tabName') }}</template>
        <project-settings-corporate-identity
          class="mt-5"
          :corporate-identity-data="corporateIdentity"
          :custom-group-fonts="customGroupFonts"
          :is-sys-admin="userObject.isSysAdmin"
          @update-ci-settings="updateCISettings"
        ></project-settings-corporate-identity>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_EMBED'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.project.embedded.hash')"
        @click="setUrl($t('settings.project.embedded.hash'))"
      >
        <template #title>{{ $t('settings.project.embedded.tabName') }}</template>
        <project-settings-embedded
          class="mt-5"
          :project-id="currentItemObject.id"
          :group-slug="groupSlug"
          :project-name="projectName"
          :group-domain="groupDomain"
          :group-id="groupId"
          :name="publicationData.name"
          :publication-data-prop="publicationData"
        ></project-settings-embedded>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_NAVIGATION'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.project.projectType.hash')"
        @click="setUrl($t('settings.project.projectType.hash'))"
      >
        <template #title>{{ $t('settings.project.projectType.tabName') }}</template>
        <project-settings-project-type
          v-if="groupId"
          class="mt-5"
          :theme-id="themeId"
          :group-id="groupId"
          :theme-settings="themeSettings"
          @update-theme-settings="updateThemeSettings"
        ></project-settings-project-type>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_PLUGINS'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.plugins.hash')"
        @click="setUrl($t('settings.plugins.hash'))"
      >
        <template #title>{{ $t('settings.plugins.tabName') }}</template>
        <plugin-list
          class="mt-5"
          :key="componentKey"
          :project-id="currentItemObject.id"
          :is-sys-admin="userObject.isSysAdmin"
          :privileges="currentItemObject.privilegesForItem"
          @update-plugin-settings="updatePluginSettings"
        ></plugin-list>
      </b-tab>
      <b-tab v-if="$hasPrivileges(
          $privileges('CAN_EDIT_CUSTOM_CSS'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.customCss.hash')"
        @click="setUrl($t('settings.customCss.hash'))"
        lazy
      >
        <template #title>Custom CSS</template>
        <b-row class="mb-4 mt-5">
          <b-col cols="12" offset-sm="1" sm="10">
            <h3>
              {{ $t('settings.customCss.tabTitle') }}
            </h3>
            <hr>
            <p>
              {{ $t('settings.customCss.tabDesc') }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-4 pb-4">
          <b-col cols="12" offset-sm="1" sm="10">
            <label>
              {{ $t('settings.customCss.customCssLabel') }}
            </label>
          </b-col>
          <b-col cols="12" offset-sm="1" sm="10">
            <custom-css-editor
              :cssCode="customCss"
              @update-cssCode="updateCustomCss"
            />
            <b-form-text>
              {{ $t('settings.customCss.customCssDesc') }}
            </b-form-text>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab lazy :ref="$t('settings.analytics.hash')"
        @click="setUrl($t('settings.analytics.hash'))">
        <template #title>{{ $t('settings.analytics.usefathomTabName') }}</template>
        <usefathom-analytics-dashboard
          v-if="slugPathFromUrl && groupSlug && groupDomain && currentItemObject"
          :group-domain="groupDomain"
          :group-slug="groupSlug"
          :slug-path-from-url="slugPathFromUrl"
          :item-id="currentItemObject.id"
          item-type="project"
          :showReadersInTable="showReadersInTable"
          :isSysAdmin="userObject.isSysAdmin"
        />
      </b-tab>
      <b-tab lazy
             v-if="userObject.isSysAdmin"
             :ref="'pirsch'"
             @click="setUrl('pirsch')"
      >
        <template #title>{{ $t('settings.analytics.pirschTabName') }}</template>
        <pirsch-analytics-dashboard
          v-if="currentItemObject && pirschCode"
          :item-id="currentItemObject.id"
          item-type="project"
          :pirschCode="pirschCode"
          :showReadersInTable="showReadersInTable"
          :isSysAdmin="userObject.isSysAdmin"
        />
        <div v-else-if="!pirschCode">
          <b-row class="row mb-4 mt-5">
            <b-col cols="12" offset-sm="1" sm="10">
              {{ $t('settings.analytics.noPirschCode') }}
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab lazy :ref="'stats'"
        v-if="userObject.isSysAdmin"
        @click="setUrl('stats')">
        <template #title>{{ $t('settings.analytics.tabName') }}</template>
        <merged-analytics-dashboard
          v-if="slugPathFromUrl && groupDomain && currentItemObject && pirschCode"
          :group-domain="groupDomain"
          :slug-path-from-url="slugPathFromUrl"
          :item-id="currentItemObject.id"
          :item-type="currentItemObject.type"
          :pirsch-code="pirschCode"
        />
        <div v-else-if="!pirschCode">
          <b-row class="row mb-4 mt-5">
            <b-col cols="12" offset-sm="1" sm="10">
              {{ $t('settings.analytics.noPirschCode') }}
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab v-if="hasGroupPrivileges(
          $privileges('CAN_CREATE_PDF'),
          groupPrivileges,
          userObject.isSysAdmin,
        )"
             :ref="$t('settings.pdf.hash')"
             @click="setUrl($t('settings.pdf.hash'))"
             lazy
      >
        <template #title>{{ $t('settings.pdf.tabTitle') }}</template>
        <pdf-export
          :project-id="currentItemObject.id"
        ></pdf-export>
      </b-tab>
    </b-tabs>
    <modal-leave-page ref="leaveModalWindow"/>
  </b-container>
</template>

<script>
import DeleteProjectTag from '@/graphQlQueries/mutations/deleteProjectTag';
import DeleteTag from '@/graphQlQueries/mutations/deleteTag';
import InsertPlugin from '@/graphQlQueries/mutations/insertPlugin';
import InsertProjectTag from '@/graphQlQueries/mutations/insertProjectTag';
import InsertTag from '@/graphQlQueries/mutations/insertTag';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetPluginPasswordProtection from '@/graphQlQueries/mutations/setPluginPasswordProtection';
import SetProjectSettings from '@/graphQlQueries/mutations/setProjectSettings';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import UpdateSearchableOnPagesByIds from '@/graphQlQueries/mutations/updateSearchableOnPagesByIds';
import UpdateProjectPlugin from '@/graphQlQueries/mutations/updateProjectPlugin';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import GetAllAvailableLanguages from '@/graphQlQueries/queries/getAllAvailableLanguages';
import GetAllItemsOfFolderById from '@/graphQlQueries/queries/getAllItemsOfFolderById';
import GetAllPageAndProjectIdsByTagId from '@/graphQlQueries/queries/getAllPageAndProjectIdsByTagId';
import GetAllPluginDataOfProject from '@/graphQlQueries/queries/getAllPluginDataOfProject';
import GetAllTagsOfGroup from '@/graphQlQueries/queries/getAllTagsOfGroup';
import GetDataHasChangedForSavingFromCache from '@/graphQlQueries/queries/getDataHasChangedForSavingFromCache';
import GetGroupPrivilegesByGroupId from '@/graphQlQueries/queries/getGroupPrivilegesByGroupId';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetLanguageById from '@/graphQlQueries/queries/getLanguageById';
import GetProjectSettingsById from '@/graphQlQueries/queries/getProjectSettingsById';
import GetProjectWithChildPagesDataToPublish from '@/graphQlQueries/queries/getProjectWithChildPagesDataToPublish';
import BeforeLeavingRouteOpenModal from '@/mixins/beforeLeavingRouteOpenModal';
import CheckAccessForTeamUser from '@/mixins/checkAccessForTeamUser';
import GetItemFromUrl from '@/mixins/getItemFromUrl';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import SetDataChangedInCache from '@/mixins/setDataChangedInCache';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import { isEqual } from 'lodash';

const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export default {
  name: 'ProjectSettings',
  mixins: [
    BeforeLeavingRouteOpenModal,
    UserDataAndAccess,
    GetItemFromUrl,
    CheckAccessForTeamUser,
    SetDataChangedInCache,
    SendPublishedDataToServer,
  ],
  components: {
    ModalIncompatibleItem: () => import('@/components/modals/ModalIncompatibleItem.vue'),
    ModalLeavePage: () => import('@/components/modals/ModalLeavePage.vue'),
    ProjectSettingsProjectType: () => import('@/components/settings/projects/ProjectSettingsProjectType.vue'),
    ProjectSettingsEmbedded: () => import('@/components/settings/projects/ProjectSettingsEmbedded.vue'),
    ProjectSettingsCorporateIdentity: () => import('@/components/settings/projects/ProjectSettingsCorporateIdentity.vue'),
    ProjectSettingsPublications: () => import('@/components/settings/projects/ProjectSettingsPublications.vue'),
    PluginList: () => import('@/components/settings/PluginList.vue'),
    CustomCssEditor: () => import('@/components/helper/CustomCssEditor.vue'),
    UsefathomAnalyticsDashboard: () => import('@/components/settings/analytics/UsefathomAnalyticsDashboard.vue'),
    MergedAnalyticsDashboard: () => import('@/components/settings/analytics/MergedAnalyticsDashboard.vue'),
    PdfExport: () => import('@/components/settings/projects/ProjectSettingsPdfExport.vue'),
  },
  props: {
    parametersOfUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    pageType: 'project-settings',
    slugPathFromUrl: null,
    customGroupFonts: null,
    projectSettings: {},
    projectSettingsOriginal: {},
    corporateIdentity: {},
    corporateIdentityOriginal: {},
    themeId: null,
    themeIdOriginal: null,
    themeSettings: null,
    themeSettingsOriginal: null,
    idObject: null,
    publicationData: null,
    publicationDataOriginal: null,
    projectId: null,
    projectName: null,
    customCss: null,
    customCssOriginal: null,
    oldSlug: null,
    isPublished: null,
    parentSlugPath: null,
    groupSlug: null,
    updatedPluginData: {},
    groupDomain: null,
    pluginDataFromDB: {},
    componentKey: 0,
    allowQueryOfPublishData: false,
    parentFolderIdOfFolder: null,
    allChildSlugsOfParentFolder: null,
    allTagsOfGroup: null,
    runProjectSettings: true,
    allTagsOfGroupWithId: [],
    projectTags: [],
    projectTagsOriginal: [],
    tabIndex: 0,
    analyticsWebmagPluginActivated: false,
    analyticsWebmagPluginCookiesActivated: false,
    pirschCode: null,
    groupPrivileges: null,
  }),
  computed: {
    showReadersInTable() {
      return !!this.analyticsWebmagPluginCookiesActivated;
    },
  },
  created() {
    this.slugPathFromUrl = `/${this.parametersOfUrl}`; // paramsOfUrl always exists
    this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: this.pageType,
      },
    });
    this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  // We need to activate the tab here because DOM elements are not rendered in 'created()'
  async mounted() {
    // This is to remove the '#' from the string
    const hash = this.$route.hash.substr(1);
    if (hash === this.$t('settings.project.corporateIdentity.hash')) {
      // eslint-disable-next-line max-len
      // interval here is needed as we need to wait for the sub component 'b-tab' to finish rendering
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.project.corporateIdentity.hash')]) {
          this.$refs[this.$t('settings.project.corporateIdentity.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.project.embedded.hash')) {
      // eslint-disable-next-line max-len
      // interval here is needed as we need to wait for the sub component 'b-tab' to finish rendering
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.project.embedded.hash')]) {
          this.$refs[this.$t('settings.project.embedded.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.project.projectType.hash')) {
      // eslint-disable-next-line max-len
      // interval here is needed as we need to wait for the sub component 'b-tab' to finish rendering
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.project.projectType.hash')]) {
          this.$refs[this.$t('settings.project.projectType.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.plugins.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.plugins.hash')]) {
          this.$refs[this.$t('settings.plugins.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.customCss.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.customCss.hash')]) {
          this.$refs[this.$t('settings.customCss.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.analytics.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.analytics.hash')]) {
          this.$refs[this.$t('settings.analytics.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.pdf.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.pdf.hash')]) {
          this.$refs[this.$t('settings.pdf.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === 'stats') {
      const interval = setInterval(() => {
        if (this.$refs.stats) {
          this.$refs.stats.activate();
          clearInterval(interval);
        }
      }, 50);
    }
  },
  apollo: {
    groupSlug: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups.length) {
          this.groupDomain = data.groups[0].domain;
          this.pirschCode = data.groups[0].pirsch_code;
          this.customGroupFonts = [];
          if (data.groups[0].settings?.customFonts) {
            Object.entries(data.groups[0].settings.customFonts)
              .forEach((fontArray) => {
                let customFontFamily = fontArray[1].fontFamily;
                // check for quotes in font name
                if (/^'.*'$/.test(customFontFamily)) {
                  customFontFamily = customFontFamily.substring(1, customFontFamily.length - 1);
                }
                if (!this.customGroupFonts.includes(customFontFamily)) {
                  this.customGroupFonts.push(customFontFamily);
                }
              });
          }
          return data.groups[0].slug;
        }
        return null;
      },
      skip() {
        return !this.groupId;
      },
    },
    groupPrivileges: {
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      query: GetGroupPrivilegesByGroupId,
      update(data) {
        if (data.groups_by_pk.groups_privileges.length > 0) {
          return data.groups_by_pk.groups_privileges.map((item) => ({
            handle: item.privilege.handle,
            value: item.value,
          }));
        }
        return null;
      },
      skip() {
        return !this.groupId;
      },
    },
    languageNameByIdQuery: {
      query: GetLanguageById,
      variables: {
        languageId: 12134,
      },
      update(data) {
        return data;
      },
    },
    pluginDataFromDB: {
      query: GetAllPluginDataOfProject,
      variables() {
        return {
          projectId: this.currentItemObject.id,
        };
      },
      update(data) {
        const pluginDataOfDb = {};
        data.plugins.forEach((dataItem) => {
          pluginDataOfDb[dataItem.handle] = {
            enabled: dataItem.enabled,
            settings: dataItem.settings,
          };
        });
        this.checkForAnalyticsDashboard(pluginDataOfDb);
        return pluginDataOfDb;
      },
      skip() {
        return !this.currentItemObject;
      },
      fetchPolicy: 'network-only',
    },
    allChildSlugsOfParentFolder: {
      query: GetAllItemsOfFolderById,
      variables() {
        return {
          id: this.parentFolderIdOfFolder,
        };
      },
      update(data) {
        // todo: change the properties to make it work
        // first get all slugs of folders
        const slugsOfAllChildItems = [];
        if (data.hasOwnProperty('folders')) {
          for (let i = 0; i < data.folders.length; i++) {
            if (this.oldSlug !== data.folders[i].slug) {
              slugsOfAllChildItems.push(data.folders[i].slug);
            }
          }
        }
        if (data.hasOwnProperty('links')) {
          for (let i = 0; i < data.links.length; i++) {
            // don't add the current project
            if (this.publicationData.slug !== data.links[i].slug) {
              if (this.oldSlug !== data.links[i].slug) {
                slugsOfAllChildItems.push(data.links[i].slug);
              }
            }
          }
        }
        return slugsOfAllChildItems;
      },
      skip() {
        return !this.parentFolderIdOfFolder || !this.oldSlug;
      },
    },
    allTagsOfGroup: {
      query: GetAllTagsOfGroup,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        const allTags = [];
        this.allTagsOfGroupWithId = [];
        data.tags.forEach((tagItem) => {
          if (!allTags.includes(tagItem.name)) {
            allTags.push(tagItem.name);
            this.allTagsOfGroupWithId.push({ tagId: tagItem.tag_id, name: tagItem.name });
          }
        });
        // save all the tags with the corresponding id for saving it later
        return allTags;
      },
      skip() {
        return !this.groupId;
      },
      fetchPolicy: 'network-only',
    },
    projectSettings: {
      query: GetProjectSettingsById,
      variables() {
        return {
          id: this.currentItemObject.id,
        };
      },
      update(data) {
        if (data.projects_by_pk) {
          return this.setupTheProjectSetting(data);
        }
        return null;
      },
      skip() {
        return !this.currentItemObject || (this.currentItemObject && this.currentItemObject.type !== 'project') || !this.runProjectSettings;
      },
      fetchPolicy: 'network-only',
    },
    dataFromTheDBForPublishing: {
      query() {
        return GetProjectWithChildPagesDataToPublish;
      },
      variables() {
        return {
          projectId: this.currentItemObject.id,
        };
      },
      update(data) {
        this.allowQueryOfPublishData = false;
        this.updateProjectSettings(data);
        return data;
      },
      skip() {
        // run this query only when we set the 'doPublishTheCurrentItem' variable
        return !this.currentItemObject || !this.allowQueryOfPublishData;
      },
      fetchPolicy: 'network-only',
    },
    hasDataForSavingChangedObject: {
      query: GetDataHasChangedForSavingFromCache,
      update(data) {
        if (data.dataHasChangedForSaving) {
          if (data.dataHasChangedForSaving.isSubmittedButtonPressed) {
            // set allowQueryOfPublishData so that the publish Query is triggered
            this.allowQueryOfPublishData = true;
          } else if (data.dataHasChangedForSaving.isCancelButtonPressed) {
            this.resetSettings();
          }
        }
        return data;
      },
    },
  },
  methods: {
    setUrl(hash) {
      const regex = /#.*$/ig;
      let url = '';
      if (regex.test(this.$route.fullPath)) {
        url = this.$route.fullPath.replace(regex, (hash !== '') ? `#${hash}` : '');
      } else {
        url = this.$route.fullPath.concat((hash !== '') ? `#${hash}` : '');
      }
      window.history.pushState({}, '', url);
    },
    hasGroupPrivileges(privilege, groupPrivileges, isSysAdmin) {
      if (isSysAdmin) return true;
      if (groupPrivileges && groupPrivileges.length > 0) {
        return groupPrivileges.some((item) => item.handle === privilege);
      }
      return false;
    },
    checkForAnalyticsDashboard(pluginData) {
      if (
        pluginData.plugin_analytics_webmag
        && pluginData.plugin_analytics_webmag.enabled === true
      ) {
        this.analyticsWebmagPluginActivated = true;
      }
      if (
        pluginData.plugin_analytics_webmag_cookies
        && pluginData.plugin_analytics_webmag_cookies.enabled === true
      ) {
        this.analyticsWebmagPluginCookiesActivated = true;
      }
    },
    setupTheProjectSetting(data) {
      const projectData = { ...data.projects_by_pk };
      this.oldSlug = projectData.slug;
      this.isPublished = projectData.published;
      this.projectName = projectData.name;
      this.parentFolderIdOfFolder = projectData.parent_folder_id;

      this.publicationData = {
        name: projectData.name,
        og_title: projectData.og_title,
        slug: projectData.slug,
        description: projectData.description,
        thumbnails: { ...projectData.thumbnails },
        hreflang: projectData.hreflang,
        searchable: projectData.searchable,
        listed: projectData.listed,
        language_id: projectData.language_id,
        is_shared_template: projectData.is_shared_template,
      };
      this.customCss = projectData.custom_css;
      this.customCssOriginal = projectData.custom_css;
      this.publicationDataOriginal = JSON.parse(JSON.stringify(this.publicationData));
      this.addTagsInArray(projectData.projects_tags);
      this.corporateIdentity = projectData.corporate_identity;
      this.corporateIdentityOriginal = { ...projectData.corporate_identity };
      this.themeId = projectData.theme_id;
      this.themeIdOriginal = projectData.theme_id;
      this.themeSettings = projectData.theme_settings;
      this.themeSettingsOriginal = { ...projectData.theme_settings };
      // eslint-disable-next-line prefer-destructuring
      this.projectSettingsOriginal = { ...data.project_by_pk };
      this.runProjectSettings = false;
      return data.project_by_pk;
    },
    updateCustomCss(customCss) {
      let hasDataChanged = false;
      this.customCss = customCss;
      if (this.customCssOriginal === null && customCss !== '') {
        hasDataChanged = true;
      }
      if (this.customCssOriginal !== null && customCss !== this.customCssOriginal) {
        hasDataChanged = true;
      }
      this.projectSettingsChanged(hasDataChanged);
    },
    addTagsInArray(rawTags) {
      const tagArray = [];
      rawTags.forEach((tagItem) => {
        tagArray.push(tagItem.tag.name);
      });
      this.projectTags = [...tagArray];
      this.projectTagsOriginal = [...tagArray];
    },
    showLeaveModal() {
      this.$refs.leaveModalWindow.$refs.leaveModal.show();
    },
    resetCacheForHeaderButtons() {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged: false,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: this.isPublished,
      });
    },
    async updateProjectSettings(dataFromTheDBForPublishing) {
      try {
        // eslint-disable-next-line max-len
        const isSearchableChanged = (this.publicationDataOriginal.searchable !== this.publicationData.searchable);
        const projectId = this.currentItemObject.id;
        const { oldSlug } = this;
        const projectObject = {
          og_title: this.publicationData.og_title,
          slug: this.publicationData.slug,
          hreflang: this.publicationData.hreflang,
          name: this.publicationData.name,
          description: this.publicationData.description,
          searchable: this.publicationData.searchable,
          language_id: this.publicationData.language_id,
          is_shared_template: this.publicationData.is_shared_template,
          listed: this.publicationData.listed,
          thumbnails: this.publicationData.thumbnails,
          corporate_identity: this.corporateIdentity,
          theme_id: this.themeId,
          theme_settings: this.themeSettings,
        };
        // add the plugins update to the project
        const publishProjectObject = {
          ...dataFromTheDBForPublishing.projects_by_pk,
          ...projectObject,
        };
        const { data: { languages: allAvailableLanguages } } = await this.$apollo.query({
          query: GetAllAvailableLanguages,
        });
        const childPageIds = [];
        publishProjectObject.child_pages.forEach((childPage, index) => {
          childPageIds.push(childPage.page_id);
          const localNameObject = allAvailableLanguages.find(
            (languageItem) => languageItem.language_id === childPage.language_id,
          );
          publishProjectObject.child_pages[index].searchable = this.publicationData.searchable;
          publishProjectObject.child_pages[index].locale = (localNameObject)
            ? localNameObject.handle
            : null;
        });
        publishProjectObject.plugins = this.updatePluginsUpdateOfProjectForPublishing(
          publishProjectObject,
        );
        let result = null;
        if (projectObject.language_id) {
          result = await this.$apollo.queries.languageNameByIdQuery.refetch(
            { languageId: projectObject.language_id },
          );
        }
        publishProjectObject.locale = (result?.data?.languages_by_pk?.handle)
          ? result.data.languages_by_pk.handle
          : null;
        publishProjectObject.tags = this.projectTags;
        // build the updated publish object
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'updateProjectSettings',
          data: {
            old_slug: oldSlug,
            parent_slug_path: this.parentSlugPath,
            group_slug: this.groupSlug,
            ...publishProjectObject,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          // after the data was published add the custom_css to write into the database
          projectObject.custom_css = this.customCss;
          // update the project tags
          this.updateProjectTags();
          const tagsForCacheUpdate = [];
          this.projectTags.forEach((tag) => {
            tagsForCacheUpdate.push({ tag: { name: tag } });
          });
          await this.$apollo.mutate({
            mutation: SetProjectSettings,
            variables: {
              projectId,
              projectObject,
            },
            update: (cache) => {
              const cachedProjectSettings = cache.readQuery({
                query: GetProjectSettingsById,
                variables: { id: projectId },
              });
              const newProjectSettingsForCache = {
                ...cachedProjectSettings.projects_by_pk,
                ...projectObject,
              };
              cache.writeQuery({
                query: GetProjectSettingsById,
                variables: { id: projectId },
                data: { projects_by_pk: newProjectSettingsForCache },
              });
              // update also all the other entries -> originals
              this.setupTheProjectSetting({ projects_by_pk: newProjectSettingsForCache });
            },
          });
          await this.savePluginSettingsInDB();
          if (isSearchableChanged) {
            await this.saveSearchableInAllChildPages(childPageIds, this.publicationData.searchable);
          }
          this.resetCacheForHeaderButtons();
          this.projectTagsOriginal = [...this.projectTags];

          // delete the cache for the project
          const domain = this.groupDomain;
          let parent_slug_path = this.parentSlugPath;
          if (parent_slug_path === '/') {
            parent_slug_path = '';
          } else if (parent_slug_path.length > 1 && parent_slug_path[0] === '/') {
            parent_slug_path = parent_slug_path.substring(1, parent_slug_path.length);
          }
          let slug_path = `${parent_slug_path ? `${parent_slug_path}/` : ''}${oldSlug}`;
          slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));
          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path,
            },
          });
          if (oldSlug !== projectObject.slug) {
            this.redirectToNewPageAfterSlugChange(oldSlug);
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    redirectToNewPageAfterSlugChange(oldSlug) {
      try {
        // build the new url by replacing the old slug with the new slug
        const path = this.$route.params.parametersOfUrl;
        const pos = path.lastIndexOf(oldSlug);
        const redirectPath = `/${path.substring(0, pos)}${this.publicationData.slug}/project-settings`;
        // we need the timeout because the item_lookup table has to be updated
        setTimeout(() => {
          this.$router.push({ path: redirectPath });
        }, 1000);
      } catch (e) {
        console.error('Error while redirecting to new project settings after slug change', e);
      }
    },
    async saveSearchableInAllChildPages(pageIds, isSearchable) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateSearchableOnPagesByIds,
          variables: {
            pageIds,
            isSearchable,
          },
        });
      } catch (e) {
        console.error('Child pages couldn\'t be updated!');
      }
    },
    updateProjectTags() {
      if (equalsIgnoreOrder(this.projectTags, this.projectTagsOriginal)) return false;
      const addedElements = [];
      const removedElements = [];
      this.projectTags.forEach((tag) => {
        if (!this.projectTagsOriginal.includes(tag)) {
          addedElements.push(tag);
        }
      });
      this.projectTagsOriginal.forEach((tag) => {
        if (!this.projectTags.includes(tag)) {
          removedElements.push(tag);
        }
      });
      if (addedElements.length > 0) {
        // check if tag already existed in the database
        addedElements.forEach(async (addedElement) => {
          if (this.allTagsOfGroup.includes(addedElement)) {
            // tag is already in the db, add only the entry in the pages_tags
            const { tagId } = this.allTagsOfGroupWithId.find((item) => item.name === addedElement);
            await this.$apollo.mutate({
              mutation: InsertProjectTag,
              variables: {
                projectId: this.currentItemObject.id,
                tagId,
              },
            });
          } else {
            // we have to add the entry in the tags table and the pages_tags
            // data.insert_tags.returning[0].tag_id
            const tagReturnObject = await this.$apollo.mutate({
              mutation: InsertTag,
              variables: {
                groupId: this.groupId,
                name: addedElement,
              },
            });
            // refetch the all tags query
            const tagId = tagReturnObject.data.insert_tags.returning[0].tag_id;
            await this.$apollo.mutate({
              mutation: InsertProjectTag,
              variables: {
                projectId: this.currentItemObject.id,
                tagId,
              },
            });
          }
        });
      }

      if (removedElements.length > 0) {
        removedElements.forEach(async (removedElement) => {
          // remove the element from the pages_tags table
          const { tagId } = this.allTagsOfGroupWithId.find((item) => item.name === removedElement);
          await this.$apollo.mutate({
            mutation: DeleteProjectTag,
            variables: {
              projectId: this.currentItemObject.id,
              tagId,
            },
          });
          const {
            data: {
              tags_by_pk,
            },
          } = await this.$apollo.query({
            query: GetAllPageAndProjectIdsByTagId,
            variables: {
              tagId,
            },
            fetchPolicy: 'network-only',
          });
          if (!this.hasMoreTagsAppearanceInGroup(tags_by_pk)) {
            await this.$apollo.mutate({
              mutation: DeleteTag,
              variables: {
                tagId,
              },
            });
          }
        });
      }

      return true;
    },
    hasMoreTagsAppearanceInGroup(tagsByTagId) {
      return !(tagsByTagId.pages_tags.length === 0 && tagsByTagId.projects_tags.length === 0);
    },
    getParentFolderIdOfFolderByBreadcrumb(breadcrumbsObject) {
      if (breadcrumbsObject.length > 1) {
        return breadcrumbsObject[breadcrumbsObject.length - 2].folder_id;
      }
      return null;
    },
    updatePublicationSettings(handle, publicationData) {
      if (handle === 'language') {
        this.publicationData.language_id = publicationData.id;
      }
      if (handle === 'coverImage') {
        if (this.publicationData.thumbnails) {
          this.publicationData.thumbnails.cover_image = publicationData;
        } else {
          this.publicationData.thumbnails = { cover_image: publicationData };
        }
      }
      if (handle === 'tags') {
        this.projectTags = publicationData;
      } else {
        this.publicationData[handle] = publicationData;
      }
      let isDataChanged = false;
      if (handle === 'tags' && !equalsIgnoreOrder(publicationData, this.projectTagsOriginal)) {
        isDataChanged = true;
      }
      Object.entries(this.publicationDataOriginal).forEach((element) => {
        const theValue = element[1];
        const theKey = element[0];
        console.log('the element', element);
        switch (theKey) {
          // exclude keys which are not relevant
          case '__typename':
            break;
          case 'thumbnails':
            if (!isEqual(this.publicationData.thumbnails, theValue)) {
              isDataChanged = true;
            }
            break;
          default:
            if (theValue !== this.publicationData[theKey]) {
              // we have to take this case because on the first time empty means theValue === null
              // and this.pageData[theKey] === ''
              if (!theValue && !this.publicationData[theKey]) break;
              isDataChanged = true;
            }
        }
      });
      this.projectSettingsChanged(isDataChanged);
    },
    updateCISettings(ciData) {
      this.corporateIdentity = ciData;
      let isDataChanged = false;
      if (!isEqual(ciData, this.corporateIdentityOriginal)) {
        isDataChanged = true;
      }
      this.projectSettingsChanged(isDataChanged);
    },
    updateThemeSettings(theme) {
      this.themeId = theme.themeId;
      this.themeSettings = theme.settings;
      let isDataChanged = false;

      // remove the image from the themeSettings in an cloned object
      const themeSettingsComp = { ...this.themeSettings };
      const themeSettingsOriginalComp = { ...this.themeSettingsOriginal };
      delete themeSettingsComp.logoimage;
      delete themeSettingsOriginalComp.logoimage;

      if (this.themeId !== this.themeIdOriginal) {
        isDataChanged = true;
      } else {
        if (!isEqual(this.themeSettingsOriginal.logoimage, this.themeSettings.logoimage)) {
          if (this.themeSettings.logoimage.url !== null
            && this.themeSettingsOriginal.logoimage !== null) {
            isDataChanged = true;
          }
        }
        if (!isEqual(themeSettingsOriginalComp, themeSettingsComp)) {
          // eslint-disable-next-line array-callback-return
          Object.entries(themeSettingsComp).map((element) => {
            const theKey = element[0];
            const theValue = element[1];
            if (themeSettingsOriginalComp[theKey] === undefined) {
              if (theValue !== null && theValue !== false) {
                // special check for custom icon fa-heart
                if (theValue !== 'fa-heart') {
                  isDataChanged = true;
                }
              }
            } else if (!isEqual(themeSettingsOriginalComp[theKey], theValue)) {
              // check if both values are empty because of null vs. empty string
              if (theValue || themeSettingsOriginalComp[theKey]) {
                isDataChanged = true;
              }
            }
          });
        }
      }
      this.projectSettingsChanged(isDataChanged);
    },
    updatePluginSettings(pluginData) {
      this.updatedPluginData = {
        ...this.updatedPluginData,
        ...pluginData,
      };
      let isDataChanged = false;
      // go through all keys of object
      // eslint-disable-next-line array-callback-return
      Object.entries(this.updatedPluginData).map((element) => {
        const handle = element[0];
        const pluginSettings = element[1];
        // if there is no entry in the DB and the plugin is enabled
        if (!pluginSettings.dataFromDb && pluginSettings.enabled) {
          isDataChanged = true;
        } else if (pluginSettings.dataFromDb && this.pluginDataFromDB[handle] !== undefined) {
          // check if plugin was disabled before
          if (this.pluginDataFromDB[handle].enabled !== pluginSettings.enabled) {
            isDataChanged = true;
          } else if (pluginSettings.enabled) {
            // check if plugin settings is array (because of intro and variables) and
            // check if the length of the array is different delete function
            if (
              Array.isArray(pluginSettings.settings)
              && pluginSettings.settings.length !== this.pluginDataFromDB[handle].settings.length
            ) {
              isDataChanged = true;
            }
            // eslint-disable-next-line array-callback-return
            Object.entries(pluginSettings.settings).map((elem) => {
              const settingValue = elem[1];
              const settingKey = elem[0];
              if (settingKey === 'hash') {
                if (settingValue !== '****!'
                  && settingValue !== this.pluginDataFromDB[handle].settings[settingKey]) {
                  isDataChanged = true;
                }
              } else if (settingKey === 'imageLink') {
                if (!isEqual(this.pluginDataFromDB[handle].settings[settingKey], settingValue)) {
                  isDataChanged = true;
                }
              } else if (!isEqual(
                settingValue,
                this.pluginDataFromDB[handle].settings[settingKey],
              )) {
                isDataChanged = true;
              }
            });
          }
        }
      });
      this.projectSettingsChanged(isDataChanged);
    },
    projectSettingsChanged(isDataChanged = true) {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: this.isPublished,
      });
    },
    async resetSettings() {
      this.runProjectSettings = true;
      this.$apollo.queries.groupSlug.refresh();
      this.$apollo.queries.projectSettings.refresh();
      this.resetCacheForHeaderButtons();
    },
    async savePluginSettingsInDB() {
      Object.entries(this.updatedPluginData).map(
        async ([handle, item]) => {
          try {
            if (handle === 'plugin_password_protection') {
              const pluginData = {
                project_id: this.currentItemObject.id,
                enabled: item.enabled,
                title: item.settings.title,
                buttonText: item.settings.buttonText,
                text: item.settings.text,
                bgLink: (item.settings?.bgLink?.url) ? item.settings.bgLink.url : '',
                color: item.settings.color,
              };
              // set the password only if there is a new default string!
              if (item.settings.hash !== '****!') {
                pluginData.password = item.settings.hash;
              }
              await this.$apollo.mutate({
                mutation: SetPluginPasswordProtection,
                variables: {
                  pluginData,
                },
              });
            } else if (item.dataFromDb) {
              await this.$apollo.mutate({
                mutation: UpdateProjectPlugin,
                variables: {
                  projectId: this.currentItemObject.id,
                  handle,
                  isActive: item.enabled,
                  settings: item.settings,
                },
              });
            } else {
              await this.$apollo.mutate({
                mutation: InsertPlugin,
                variables: {
                  object: {
                    folder_id: null,
                    project_id: this.currentItemObject.id,
                    handle: item.handle,
                    enabled: item.enabled,
                    settings: item.settings,
                  },
                },
              });
            }
            // rerender the plugin-component!
            this.forceRerenderPluginComponent();
            this.updatedPluginData = {};
          } catch (ex) {
            console.log(ex);
          }
        },
      );
    },
    forceRerenderPluginComponent() {
      this.componentKey += 1;
    },
    updatePluginsUpdateOfProjectForPublishing(publishProjectObject) {
      const resultArray = publishProjectObject.plugins;
      Object.entries(this.updatedPluginData).forEach(
        ([handle, value]) => {
          const indexOfMatchedPluginItem = resultArray.findIndex((item) => item.handle === handle);
          if (indexOfMatchedPluginItem !== -1) {
            resultArray[indexOfMatchedPluginItem] = value;
          } else {
            resultArray.push(value);
          }
        },
      );
      return resultArray;
    },
    forceRerenderComponent() {
      this.componentKey += 1;
    },
  },
};
</script>
